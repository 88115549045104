/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const DonationForSmsfIndiaCorpusFund = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Donation for Corpus Fund";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Corpus Fund" allowOverseas = false/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_for_Corpus_Fund", event),
    []
  );

  const renderContentHeader = () => (
    <Box
      sx={{
        backgroundColor: "rgb(223, 242, 246)",
        textAlign: "center!important",
        marginBottom: "30px",
        padding: "40px 0px",
        border: "1px solid rgb(211, 213, 210)",
      }}
    >
      <h1 sx={{ fontSize: "2.3em" }}>Heartfulness Donations</h1>
    </Box>
  );

  return (
    <LayoutHome
      contentHeader={renderContentHeader()}
      seoTitle="Donation for SMSF India - General Fund - USA - Donations"
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box>
          <div style={{ lineHeight: "1.1", marginBottom: "8px" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "2.3em",
                marginTop: "20px",
              }}
            >
              Donation for SMSF India - General Fund - Kanha Building
            </h1>
          </div>
        </Box>
        <Box>
          <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
            <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
            Spirituality Foundation, India. Sahaj Marg Spirituality Foundation
            supports all the events and programs undertaken by heartfulness. On
            completion of the donation, a confirmation email will be sent to you
            with a completed donation form attached.
          </p>
          <p>
            <b>
              Please forward this mail back to
              <a
                href="mailto:info.accounts@sahajmarg.org"
                sx={{ marginLeft: "5px", marginRight: "5px" }}
              >
                info.accounts@sahajmarg.org
              </a>
              along with its attachment as your confirmation of the donation.
            </b>
          </p>
          <p style={{ textAlign: "justify" }}>
            <b>There are no refunds for the donations.</b>
          </p>
          <p style={{ textAlign: "justify" }}>
            Donation to this fund would be used specially for the construction
            works at Kanha Ashram in Hyderabad, India.
          </p>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(1, "Contribute Now")}
          />
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default DonationForSmsfIndiaCorpusFund;
